import * as React from 'react';

import flexUtilities from './flexUtilities';

import './columns.css';

export default ({ justify, align, style = {}, wrap = false, children }) => (
  <div
    className='TwoColumnRow'
    style={{
      justifyContent: flexUtilities.justifyContent(justify),
      alignItems: flexUtilities.alignItems(align),
      flexWrap: wrap ? 'wrap' : 'nowrap',
      ...style,
    }}
  >
    {children}
  </div>
);
