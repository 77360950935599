import * as React from 'react';
import colors from '../../colors/colors';

export default ({ children, viewBox = '', stroke, fill, aspect }) => (
  <svg
    viewBox={viewBox}
    style={{
      width: aspect,
      height: aspect,
      stroke: fill ? 'none' : stroke || colors.lightBlue,
      strokeWidth: fill ? '0' : '2px',
      fill: fill || 'none',
    }}
  >
    {children}
  </svg>
);
