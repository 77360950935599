export default {
  justifyContent: (property) =>
    ({
      around: 'space-around',
      between: 'space-between',
      center: 'center',
      end: 'flex-end',
      start: 'flex-start',
    }[property] || 'flex-start'),
  alignItems: (property) =>
    ({
      center: 'center',
      end: 'flex-end',
      start: 'flex-start',
      stretch: 'stretch',
    }[property] || 'flex-start'),
};
