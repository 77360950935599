import * as React from 'react';

import colors from '../../colors/colors';
import SVGWrapper from '../display/SVGWrapper';

export default ({ aspect = '2rem' }) => (
  <SVGWrapper viewBox='0 0 244.05 138.6' aspect={aspect} fill={colors.mediumBlue}>
    <path d='M137.66 69.3c0 38.27-30.82 69.3-68.83 69.3S0 107.58 0 69.3 30.82 0 68.83 0s68.83 31.03 68.83 69.3M213.17 69.3c0 36.03-15.41 65.24-34.41 65.24-19.01 0-34.41-29.21-34.41-65.24s15.41-65.24 34.41-65.24c19 .01 34.41 29.21 34.41 65.24M244.05 69.3c0 32.28-5.42 58.45-12.1 58.45-6.69 0-12.1-26.17-12.1-58.45s5.42-58.45 12.1-58.45 12.1 26.17 12.1 58.45' />
  </SVGWrapper>
);
