import * as React from 'react';

import FlexWrapper from '../display/FlexWrapper';
import TwoColumns from '../display/TwoColumns';

import boogieWoogieColors from '../../colors/boogieWoogieColors';
import PickleWarsLogo from '../imageWrappers/PickleWarsLogo';
import pickleWarsColors from '../../colors/pickleWarsColors';

export default () => (
  <TwoColumns align='stretch' justify='between'>
    <FlexWrapper
      align='center'
      justify='center'
      style={{
        backgroundColor: pickleWarsColors.pickleWarsRed,
        padding: 'calc(2rem + 5vw)',
      }}
    >
      <PickleWarsLogo />
    </FlexWrapper>
    <FlexWrapper
      align='stretch'
      justify='between'
      column
      style={{
        backgroundColor: pickleWarsColors.pickleWarsGreen,
        padding: 0,
        alignSelf: 'stretch',
      }}
    >
      <FlexWrapper
        align='center'
        justify='center'
        column
        style={{
          height: '100%',
          padding: 'calc(2rem + 5vw)',
        }}
      >
        <p
          style={{
            color: pickleWarsColors.pickleWarsOutline,
            margin: '0 0 1rem',
            lineHeight: 1.6,
            maxWidth: '25rem',
          }}
        >
          Pickle Wars is an NFT project created by Dandy Lines focusing on the conflict between Pickle Haters United
          (PHU), and The Pickle Republic (TPR).
        </p>
        <p
          style={{
            color: pickleWarsColors.pickleWarsOutline,
            margin: '0 0 1rem',
            lineHeight: 1.6,
            maxWidth: '25rem',
          }}
        >
          We create unique characters and imagery to capture the absurdity of a world where pickles fight to survive
          eradication.
        </p>
        <a
          href='https://picklewars.io'
          style={{
            color: pickleWarsColors.pickleWarsRust,
            margin: '0 0 1rem',
            lineHeight: 1.6,
            borderColor: pickleWarsColors.pickleWarsRust,
            display: 'block',
            width: '100%',
            margin: 0,
            maxWidth: '25rem',
          }}
        >
          Visit Site
        </a>
      </FlexWrapper>
    </FlexWrapper>
  </TwoColumns>
);
