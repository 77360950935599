import * as React from 'react';

import FlexWrapper from '../display/FlexWrapper';

import boogieWoogieColors from '../../colors/boogieWoogieColors';
import BoogieWoogieLogo from '../imageWrappers/BoogieWoogieLogo';
import { Link } from 'gatsby';

const boogieWoogieContentStyle = {
  color: boogieWoogieColors.bwBlue,
  margin: '0 auto 1rem',
  width: 'calc(100% - 2rem)',
  maxWidth: '40rem',
  lineHeight: 1.6,
};

const BoogieWoogie = () => (
  <>
    <FlexWrapper
      align='center'
      justify='center'
      style={{
        backgroundColor: boogieWoogieColors.bwBlue,
        padding: 'calc(2rem + 5vw)',
      }}
    >
      <BoogieWoogieLogo />
    </FlexWrapper>
    <FlexWrapper
      align='stretch'
      justify='between'
      column
      style={{
        backgroundColor: boogieWoogieColors.bwWhite,
        padding: 0,
        alignSelf: 'stretch',
      }}
    >
      <FlexWrapper
        align='center'
        justify='center'
        column
        style={{
          height: '100%',
          padding: 'calc(2rem + 5vw)',
        }}
      >
        <p style={boogieWoogieContentStyle}>
          Our inspiration came from{' '}
          <Link to='https://www.moma.org/artists/4057' style={{ color: boogieWoogieColors.bwRed }}>
            Piet Mondrian's
          </Link>{' '}
          iconic painting&mdash;
          <Link to='https://www.moma.org/collection/works/78682' style={{ color: boogieWoogieColors.bwRed }}>
            <i>Broadway Boogie Woogie</i>
          </Link>
          . His ability to capture the essence of both the city and music by using elements of color, scale, and
          composition inspires this collection.
        </p>
        <p style={boogieWoogieContentStyle}>
          We are using our love of code to develop and iterate a generative process that outputs abstract
          representations of works of art we find inspirational. We distill these works down into blocks of color,
          controlling color balance and scale, to achieve an honest representation of the original work in the form of a
          random, unique digital rendering.
        </p>
        <p style={boogieWoogieContentStyle}>
          Each calendar quarter&mdash;starting Q4 2021 through Q4 2024&mdash;we will release a run of 125 Boogie-Woogie
          NFTs based on five (5) works of art. We will produce 25 unique 1-of-1 Boogie-Woogie NFTs per art piece, per
          run. We have reserved 345 NFTs for <Link to='https://boogie-woogie.io/#Bespoke'>Bespoke Boogie-Woogies</Link>:
          single, unique images created upon request using whatever inspirational image is supplied. Only 1970 Boogie-Woogie NFTs will be created this generation.
        </p>
        <a
          href='https://boogie-woogie.io'
          style={{
            ...boogieWoogieContentStyle,
            color: boogieWoogieColors.bwRed,
          }}
        >
          Visit Site
        </a>
      </FlexWrapper>
    </FlexWrapper>
    <FlexWrapper
      align='stretch'
      justify='between'
      style={{ backgroundColor: boogieWoogieColors.bwRed, height: '3rem' }}
    >
      <div style={{ width: '70%' }}></div>
      <div style={{ width: '10%', backgroundColor: boogieWoogieColors.bwYellowDesaturated }}></div>
      <div style={{ width: '20%', backgroundColor: boogieWoogieColors.bwYellowSaturated }}></div>
    </FlexWrapper>
  </>
);

export default BoogieWoogie;
