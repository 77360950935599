import * as React from 'react';
import { Link } from 'gatsby';

import colors from '../colors/colors';

import Layout from '../components/layouts/core';

import DandyLinesLogo from '../components/imageWrappers/DandyLinesLogo';
import FlexWrapper from '../components/display/FlexWrapper';

import BoogieWoogie from '../components/collections/BoogieWoogie';
import PickleWars from '../components/collections/PickleWars';

import './main.css';

const IndexPage = () => {
  return (
    <Layout>
      <main>
        <title>Dandy Lines</title>
        <FlexWrapper
          align='center'
          justify='center'
          column
          className='LogoWrapper'
          style={{
            width: '100%',
            height: 'calc(14rem + 15vw)',
            backgroundColor: colors.mediumBlue,
          }}
        >
          <div style={{ maxWidth: '50rem', width: '85%' }}>
            <DandyLinesLogo />
          </div>
        </FlexWrapper>
        <div
          style={{
            display: 'block',
            width: '100%',
            margin: 0,
            padding: 'calc(1.5rem + 3vw) 0',
            textAlign: 'center',
          }}
        >
          <h1
            style={{
              fontSize: 0,
              width: '1px',
              height: '1px',
              display: 'inline-block',
              overflow: 'hidden',
              position: 'absolute !important',
              border: '0 !important',
              padding: '0 !important',
              margin: '0 !important',
              clip: 'rect(1px, 1px, 1px, 1px)',
            }}
          >
            Dandy Lines
          </h1>
          <h2 style={{ margin: '-1rem 0 0' }}>We create and curate NFT collections.</h2>
          <p>
            We are currently building two collections:{' '}
            <Link to='/#Boogie-Woogie' className='Light'>
              Boogie-Woogie
            </Link>
            , and{' '}
            <Link to='/#Pickle-Wars' className='Light'>
              Pickle Wars
            </Link>
            .
          </p>
        </div>
        <div id='Boogie-Woogie'>
          <BoogieWoogie />
        </div>
        <div id='Pickle-Wars'>
          <PickleWars />
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
