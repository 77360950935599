import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default () => (
  <StaticImage
    src='../../images/boogie-woogie-logo-white.png'
    alt='Dandy Lines Boogie-Woogie Logo'
    placeholder='tracedSVG'
  />
);
