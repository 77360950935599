export default {
  pickleWarsGreen: 'var(--pickle-wars-Green)',
  pickleWarsBlue: 'var(--pickle-wars-Blue)',
  pickleWarsRed: 'var(--pickle-wars-Red)',
  pickleWarsRust: 'var(--pickle-wars-Rust)',
  PickleWarsPickle: 'var(--pickle-wars-Pickle)',
  pickleWarsOutline: 'var(--pickle-wars-Outline)',
  pickleWarsNightSky: 'var(--pickle-wars-NightSky)',
  pickleWarsNightSkyLight: 'var(--pickle-wars-NightSkyLight)',
};
