import * as React from 'react';

import colors from '../../colors/colors';

import FlexWrapper from '../display/FlexWrapper';
import ThreeColumns from '../display/ThreeColumns';

import Discord from '../icons/Discord';
import Medium from '../icons/Medium';
import Twitter from '../icons/Twitter';

const columnStyles = {
  padding: '2rem 1rem',
};

const headerStyles = {
  margin: '0 0 1rem',
  fontSize: '1.1rem',
};

const linkStyles = {
  display: 'block',
  color: colors.lightBlue,
  textDecorationColor: colors.mediumBlue,
  fontSize: '0.85rem',
  margin: '0.75rem 0',
  height: '1.25rem',
};

export default () => (
  <footer>
    <ThreeColumns>
      <div style={columnStyles}>
        <h3 style={headerStyles}>Dandy Lines</h3>
        <a style={linkStyles} href='https://twitter.com/dandylinesNFT'>
          <FlexWrapper align='center' justify='start'>
            <Twitter aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Twitter - @DandyLinesNFT</span>
          </FlexWrapper>
        </a>
        <a style={linkStyles} href='https://dandylines.medium.com/'>
          <FlexWrapper align='center' justify='start'>
            <Medium aspect='1.25rem' />
            <span style={{ marginLeft: '0.5rem' }}>Medium</span>
          </FlexWrapper>
        </a>
      </div>
      <div style={columnStyles}>
        <h3 style={headerStyles}>Our Collections</h3>
        <a style={linkStyles} href='https://boogie-woogie.io'>
          Dandy Lines Boogie-Woogie
        </a>
        <a style={linkStyles} href='https://picklewars.io'>
          Pickle Wars
        </a>
      </div>
      <div style={columnStyles}></div>
    </ThreeColumns>
  </footer>
);
