import * as React from 'react';

import flexUtilities from './flexUtilities';

const FlexWrapper = ({
  align,
  justify,
  children,
  className = '',
  column = false,
  order = 1,
  style = {},
  wrap = false,
}) => (
  <div
    className={className}
    style={{
      display: 'flex',
      justifyContent: flexUtilities.justifyContent(justify),
      alignItems: flexUtilities.alignItems(align),
      flexDirection: column ? 'column' : 'row',
      flexWrap: wrap ? 'wrap' : 'nowrap',
      order,
      ...style,
    }}
  >
    {children}
  </div>
);

export default FlexWrapper;
